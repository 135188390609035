export function checkIfNeverLoggedIn(date) {
  const loginDate = new Date(date);
  const thresholdDate = new Date("1980-01-01T00:00:00.000Z");
  return loginDate < thresholdDate;
}

export function redirectToGoogleUserInWorkspace(user_id, route = "profile") {
  if (!user_id) {
    return;
  }

  window.open(
    `https://admin.google.com/ac/users/${user_id}/${route}`,
    "_blank"
  );
}

export function getPaginationDataFromApi(
  pagination,
  hasFiltersToApply,
  currentFilters,
  currentOffset
) {
  const { sortBy, sortDesc, itemsPerPage } = pagination;

  let filters = {};

  if (hasFiltersToApply) {
    const allFilters = currentFilters.map((item) => item.value);

    allFilters.forEach((filter_name) => {
      const currentFilter = currentFilters.find(
        (filter) => filter.value === filter_name
      );

      if (currentFilter) {
        let { search } = currentFilter;
        filters[filter_name] = search;
      }
    });
  }

  let backendQuery = {
    limit: itemsPerPage,
    offset: currentOffset,
    ...filters,
  };

  if (sortBy.length === 1) {
    backendQuery.order_by = sortBy[0];
  }

  if (sortDesc.length === 1) {
    backendQuery.sort_desc = sortDesc[0];
  }

  return backendQuery;
}
