export const appSlugs = {
  "Conecta Suite": "conecta_suite",
  "Access Control 2": "conecta_control",
  Figma: "figma",
  Atlassian: "atlassian",
  "WhatsApp Messenger": "whatsapp",
  "Google Chrome": "chrome",
  "iOS Account Manager": "apple",
  "Android device": "android",
};
