<template>
  <canvas :id="this.name"></canvas>
</template>

<script>
import Chart from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

export default {
  name: "DonutChart",

  props: {
    config: {
      type: Object,
      default: function () {
        return this.configDefault;
      },
    },
    name: {
      type: String,
      default: "Users",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      chart: null,
      configDefault: {
        type: "doughnut",
        data: {
          labels: ["Carregando..."],
          datasets: [
            {
              backgroundColor: ["rgb(180, 180, 180)"],
              borderColor: "rgb(255, 255, 255)",
              data: [1],
            },
          ],
        },
        options: {
          plugins: {
            datalabels: {
              color: "white",
            },
          },
          legend: {
            position: "right",
            align: "center",
          },
        },
      },
    };
  },

  computed: {
    configToRender() {
      if (this.loading) return this.configDefault;
      else return this.config;
    },
  },

  watch: {
    loading() {
      this.updateChart(this.configToRender);
    },
  },

  methods: {
    updateChart(config) {
      if (!document.getElementById(this.name)) return;
      if (this.chart) this.chart.destroy();
      const ctx = document.getElementById(this.name).getContext("2d");
      this.chart = new Chart(ctx, config);
    },
  },
  beforeMount() {
    Chart.plugins.register(ChartDataLabels);
  },
  mounted() {
    this.updateChart(this.configToRender);
  },
};
</script>
