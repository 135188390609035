<template>
  <v-expansion-panels flat class="outlined">
    <v-expansion-panel>
      <v-expansion-panel-header>
        <div class="d-flex justify-space-between align-center">
          <div class="d-flex align-center">
            <v-avatar color="grey lighten-3" size="30">
              <v-icon v-text="icon" color="primary"></v-icon>
            </v-avatar>
            <p class="ma-0 ml-3 font-weight-bold">
              {{ title }}
            </p>
          </div>
          <div>
            <v-tooltip v-if="count > 0" bottom :color="statusColor">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  :color="statusColor"
                  v-text="statusIcon"
                />
              </template>
              <div style="max-width: 270px">
                {{ tooltipText }}
              </div>
            </v-tooltip>
            <v-icon
              v-else-if="!loading"
              :color="statusColors['OK']"
              v-text="statusIcons['OK']"
            />
            <v-tooltip
              v-if="count > 0 && licensesPrice"
              bottom
              :color="statusColor"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  v-bind="attrs"
                  v-on="on"
                  class="white--text ml-4"
                  :color="statusColor"
                  outlined
                  small
                >
                  {{
                    $t("techDiagnosis.generalRow.totalPrice", {
                      price: licensesTotalPrice,
                    })
                  }}
                </v-chip>
              </template>
              <div style="max-width: 270px">
                {{
                  $t("techDiagnosis.generalRow.totalPriceText", {
                    price: licensesTotalPrice,
                  })
                }}
              </div>
            </v-tooltip>
            <v-tooltip
              v-if="count > 0 && invalidGoogleResearch"
              bottom
              :color="statusColor"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  v-bind="attrs"
                  v-on="on"
                  class="white--text ml-4"
                  small
                  :color="statusColor"
                  outlined
                  >{{
                    $t("techDiagnosis.generalRow.totalPrice", {
                      price: licensesTotalPrice,
                    })
                  }}
                </v-chip>
              </template>
              <div style="max-width: 270px">
                {{
                  $t("techDiagnosis.generalRow.withoutResearchPriceText", {
                    price: licensesTotalPrice,
                  })
                }}
              </div>
            </v-tooltip>
            <v-chip
              v-if="diagnostic"
              class="white--text mx-4"
              :color="statusColor"
              outlined
              small
              >{{ count > 0 ? `${count} ${kindPlural}` : OK }}
            </v-chip>
          </div>
        </div>
      </v-expansion-panel-header>
      <v-progress-linear
        v-if="loading"
        indeterminate
        color="primary"
      ></v-progress-linear>
      <v-expansion-panel-content>
        <template>
          <slot name="table">
            <v-data-table
              :headers="headers"
              :items="items"
              :items-per-page="5"
              outlined
            >
              <template v-slot:top>
                <slot name="top" />
              </template>
              <template v-slot:[`item.name`]="{ item }">
                <v-list-item dense class="pa-0" v-if="isApp">
                  <v-list-item-icon class="mr-5" style="height: 36px">
                    <Logo
                      :product="logoSlug(item.name)"
                      height="36"
                      noMargins
                    />
                  </v-list-item-icon>
                  <v-list-item-content>
                    {{
                      item.name && item.name.length > 40
                        ? `${item.name.slice(0, 40)}...`
                        : item.name
                    }}
                  </v-list-item-content>
                </v-list-item>
                <div class="d-flex" v-else>
                  <UserBadge
                    :user="item"
                    size="40"
                    link
                    :redirect-to-user="redirectToUser"
                    showUserEmail
                    hideBadges
                  />
                </div>
              </template>
              <template v-slot:[`item.users_apps`]="{ item }">
                <v-avatar
                  v-for="user_app in item.users_apps.slice(0, 3)"
                  :key="user_app.user_key"
                  color="primary"
                  size="28"
                  style="display: inline-block; margin-left: -4px"
                >
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <img
                        v-bind="attrs"
                        v-on="on"
                        :src="getUserByKey(user_app.user_key).photo"
                        :alt="getUserByKey(user_app.user_key).name"
                        referrerpolicy="strict-origin-when-cross-origin"
                        style="border: solid grey 1px"
                        @error="
                          $event.target.src = defaultUserPhoto;
                          $event.onerror = null;
                        "
                      />
                    </template>
                    <span>{{ getUserByKey(user_app.user_key).email }}</span>
                  </v-tooltip>
                </v-avatar>
              </template>
              <template v-slot:[`item.last_login`]="{ item }">
                <div v-if="checkIfNeverLoggedIn(item.last_login)">
                  {{ $t("techDiagnosis.generalRow.neverLogged") }}
                </div>
                <label v-else>{{ formatDateAndHour(item.last_login) }}</label>
              </template>
            </v-data-table>
          </slot>
        </template>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { checkIfNeverLoggedIn } from "@/helpers/services/users";
import { formatDateAndHour } from "@/helpers/services/utils";
import { BAD, WARNING, OK } from "@/helpers/variables/diagnostic";
import { appSlugs } from "@/helpers/variables/itemsNApps";
import defaultUserPhoto from "@/assets/user-default.png";

export default {
  name: "DiagnosticRowItem",
  props: {
    icon: String,
    title: String,
    diagnostic: {
      type: [Object, String, Number],
      default: () => ({}),
    },
    tooltipText: String,
    kindPlural: { type: String, default: () => "usuários" },
    headers: { type: Array, default: () => [] },
    redirectToUser: { type: Boolean, default: () => false },
    loading: { type: Boolean, default: () => false },
    isApp: { type: Boolean, default: () => false },
    licensesPrice: { type: String, default: () => null },
    invalidGoogleResearch: { type: Boolean, default: () => false },
  },
  data: () => ({
    statusColors: {
      [WARNING]: "warning",
      [BAD]: "warning",
      [OK]: "success",
    },
    statusIcons: {
      [WARNING]: "mdi-alert-circle-outline",
      [BAD]: "mdi-alert-circle-outline",
      [OK]: "mdi-check-circle-outline",
    },
    OK,
    appSlugs,
    defaultUserPhoto,
    usersByKey: {},
    businessPriceLicense: 105,
  }),
  computed: {
    status() {
      return this.diagnostic?.status;
    },
    statusColor() {
      return this.statusColors[this.status] || "primary";
    },
    statusIcon() {
      return this.statusIcons[this.status] || "";
    },
    count() {
      return this.diagnostic?.count || 0;
    },
    items() {
      return this.diagnostic?.users;
    },

    licensesTotalPrice() {
      if (this.licensesPrice) {
        let totalPrice = this.count * parseFloat(this.licensesPrice);
        return totalPrice.toFixed(2);
      } else {
        let totalPrice = this.count * this.businessPriceLicense;
        return totalPrice.toFixed(2);
      }
    },
  },
  methods: {
    formatDateAndHour,
    checkIfNeverLoggedIn,

    logoSlug(app_name) {
      return this.appSlugs[app_name] || "application";
    },

    getUserByKey(key) {
      return this.diagnostic["inactive_users"][key];
    },
  },
};
</script>
